import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { Link, graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import narmArticle from "@images/narm-article.png"
import heal from "@images/Healing-Dev-Trauma-210x300.jpg"
import WebriQForm from "@webriq/gatsby-webriq-form"
import marked from "marked"
const Resources = data => {
  const { allSanityPodcast, allSanityPost } = data.data
  const podcasts = allSanityPodcast.edges.map(cast => cast.node)
  const posts = allSanityPost.edges.map(post => post.node)

  console.log("podcasts", podcasts)
  return (
    <Layout>
      <SEO
        title="Resources"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <div class="page-headline">
        <div class="container">
          <div class="section-heading text-center">
            <h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
              Resources
            </h5>
            <h1 className="font-weight-normal">
              {" "}
              <strong className="text-white">NARM</strong> ARTICLES
            </h1>
          </div>
        </div>
      </div>
      <div class="page-content mt-5">
        <Container>
          <div className="mb-5">
            <Row class="justify-content-between align-items-center row">
              <Col md={6} sm={6}>
                <img class="img-fluid" src={narmArticle} alt="peaceful" />
              </Col>
              <Col md={6} sm={6}>
                <p>NEWLY RELEASED ARTICLE</p>
                <p className="font-weight-bold">
                  We Surveyed NARM Therapists About The Effectiveness of NARM
                  for C-PTSD Characteristics & Here’s What We Found!
                </p>
                <p>
                  The prevalence of early chronic interpersonal trauma in our
                  society has created an urgent need for accurate diagnoses and
                  effective treatments for Complex Trauma. This study introduces
                  NARM Therapy as an emerging psychotherapeutic modality
                  designed specifically to treat symptoms of Complex Trauma.
                </p>
                <WebriQForm
                  name="resources Form"
                  data-form-id="198500ae-d4ad-46b4-8d08-a557f713aa55"
                  data-thankyou-url="/thank-you"
                  className="contact p-3"
                >
                  <p className="font-weight-bold">
                    Enter your contact information to access the 15-page article
                    now!
                  </p>
                  <div className="form-group align-items-center row m-0">
                    <div className="col-md px-0 mb-3 mb-md-0">
                      <input
                        className="form-control mb-md-0"
                        type="text"
                        placeholder="Name"
                        name="Name"
                      />
                    </div>
                  </div>
                  <div className="form-group align-items-center row m-0">
                    <div className="col-md px-0 mb-3 mb-md-0">
                      <input
                        className="form-control mb-md-0"
                        type="email"
                        placeholder="Email Address"
                        name="Email"
                      />
                    </div>
                  </div>
                  <div className="form-group align-items-center row m-0">
                    <div className="col-md px-0 mb-3 mb-md-0">
                      <input
                        className="form-control mb-md-0"
                        type="text"
                        placeholder="State"
                        name="State"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group align-items-center row m-0">
                    <div className="col-md-auto px-0">
                      <p className="d-flex flex-column flex-sm-row justify-content-start justify-content-md-center mb-0">
                        <button
                          className="btn btn-primary float-sm-right arrow"
                          type="submit"
                        >
                          SEND MESSAGE
                        </button>
                      </p>
                    </div>
                  </div>
                </WebriQForm>
              </Col>
            </Row>
          </div>
          <div className="mb-5">
            <h2 className="font-weight-normal">
              <strong>NARM</strong> BOOKS
            </h2>
            <Row class="justify-content-between align-items-center row">
              <Col md={9} sm={9}>
                <h3>
                  NARM Creator Dr. Laurence Heller’s latest book,{" "}
                  <i>
                    Healing Developmental Trauma: How Early Trauma Affects
                    Self-Regulation, Self-Image, and the Capacity for
                    Relationship
                  </i>
                </h3>
                <p>
                  Read the influential book that is inspiring people interested
                  in healing and growth and changing the way therapists address
                  Complex Trauma. The book and audiobook are available on{" "}
                  <a href="https://www.amazon.com/dp/1583944893/ref=asc_df_15839448935679709?tag=shopz0d-20&ascsubtag=shopzilla_mp_1498-20&15425142688980293950810080302008005&creative=395261&creativeASIN=1583944893&linkCode=asn">
                    Amazon
                  </a>
                  and{" "}
                  <a href="https://www.barnesandnoble.com/w/healing-developmental-trauma-laurence-heller/1110981083;jsessionid=366F1C89D5B86B5BA2F6F3FA1861A029.prodny_store02-atgap13?ean=9781583944899&st=AFF&2sid=VigLink_6161663_NA&sourceId=AFFVigLink#/">
                    other online booksellers
                  </a>
                  .
                </p>
              </Col>
              <Col md={3} sm={3}>
                <img class="img-fluid" src={heal} alt="peaceful" />
              </Col>
            </Row>
          </div>

          <div className="mb-5">
            <h2 className="font-weight-normal">
              <strong>NARM</strong> PODCASTS
            </h2>
            <Row>
              {podcasts.map(cast => (
                <Col md={4} sm={6} key={cast.id} className="mb-4">
                  <div
                    className="narm-wrapper h-100"
                    style={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: 3,
                      padding: 15,
                    }}
                  >
                    <div className="text-center">
                      <img
                        src={cast.mainImage.asset.fluid.src}
                        className="img-fluid mx-auto mb-4"
                        alt={
                          cast.mainImage.alt ? cast.mainImage.alt : cast.title
                        }
                      />
                    </div>

                    <a
                      href={
                        cast.podcastUrl
                          ? cast.podcastUrl
                          : `/transformingtrauma/${cast.slug.current}`
                      }
                      className="text-orange"
                      style={{ textDecoration: "none", fontWeight: "bold" }}
                    >
                      {cast.title}
                    </a>
                    {/* <div
											dangerouslySetInnerHTML={{
												__html: marked(cast.body),
											}}
										></div> */}
                    <div className="mt-3">{cast.excerpt}</div>
                    {cast.podcastUrl ? (
                      <center>
                        <a
                          href={cast.podcastUrl}
                          className="btn btn-primary mt-5"
                        >
                          View Full Podcast
                        </a>
                      </center>
                    ) : null}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="mb-5">
            <h2 className="font-weight-normal">
              <strong>NARM</strong> BLOG
            </h2>
            <Row>
              {posts.map(post => (
                <Col md={4} sm={6} key={post.id} className="mb-4">
                  <div
                    className="narm-wrapper h-100"
                    style={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: 3,
                      padding: 15,
                    }}
                  >
                    <h5 className="text-orange">{post.title}</h5>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: marked(post.excerpt),
                      }}
                    ></div>
                    <Link
                      to={`/${post.slug.current}`}
                      className="btn btn-primary"
                    >
                      Read More
                    </Link>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Resources

export const resourceQuery = graphql`
  query resourceQuery {
    allSanityPodcast(
      filter: { categories: { elemMatch: { title: { eq: "NARM Podcasts" } } } }
    ) {
      edges {
        node {
          id
          title
          excerpt
          body
          podcastUrl
          slug {
            current
          }
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
        }
      }
    }
    allSanityPost {
      edges {
        node {
          id
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`
